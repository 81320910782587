import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HeaderCarousel from '../components/carousel/carouselHeader'
import About from '../components/about/about'
import Szymon from '../components/staff/szymon'
import ProductSection from '../components/products/products'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

function IndexPage() {
    const { t } = useTranslation('homepage')
    const classes = useStyles()

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#fff',
            },
            secondary: {
                main: '#dc3545',
            },
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <SEO title={t('title')} />

                {/* <h1 className="center-font">{t('welcome')}</h1> */}

                {/* <HeaderCarousel /> */}

                <About />

                <Szymon />

                <ProductSection />

                

            </Layout>
        </ThemeProvider>
    )
}

export default IndexPage
