import React from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import MapCard from '../card/mapCard'
import cx from 'clsx'

import Typography from '@material-ui/core/Typography'

import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

/* 
Built using gatsby-background-image
https://www.gatsbyjs.com/plugins/gatsby-background-image/

 */


const BackgroundSection = ({ className }) => {

    const { t } = useTranslation('homepage')

    const data = useStaticQuery(
        graphql`
            query {
                aboutBgImage: file(relativePath: { eq: "assets/images/pullups-dark.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                storeOutside: file(relativePath: { eq: "assets/images/outside-store-01.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                storeInside: file(relativePath: { eq: "assets/images/inside-store-01.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `,
    )

    const imageData = data.aboutBgImage.childImageSharp.fluid

    return (
        <BackgroundImage
            Tag="div"
            className={cx(className, 'darken')}
            fluid={imageData}
            backgroundColor={`#040e18`}
            style={{
                marginBottom: '0',
            }}
        >
            <div id="about-section">

                <div className="about-text">
                    <h2 className="center-font highlight pt-1">{t('welcome')}</h2>
                    <h3 className="center-font shop-address">20 Abbey Street Upper, Dublin 1, Ireland, D01 DE00</h3>
                </div>

                <div className="container-inner-sm pb-3">
                    <div className="grid-container grid-about">
                        <Img
                            className="image-outside"
                            fluid={data.storeOutside.childImageSharp.fluid}
                        />

                        <Img
                            className="image-inside"
                            fluid={data.storeInside.childImageSharp.fluid}
                        />

                        <MapCard />

                        <div className="frame px-1">
                            <p className="center-font" id="">
                                {t('established_info')}
                            </p>

                            <p className="center-font">{t('we_offer')}</p>

                            <p className="center-font">{t('located_extended')}</p>

                            <p className="center-font">{t('we_stock')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}

const About = styled(BackgroundSection)`
    width: 100%;
    /* background-attachment: fixed; */
    background-position: top center;
    background-repeat: repeat-y;
    background-size: cover;
    margin-bottom: 30px;
`

export default About

/*   background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */