import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, useStaticQuery } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'

import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'


const useStyles = makeStyles({
    media: {
        height: 200,
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
})


function ProductSection() {

    const classes = useStyles()

    const { t } = useTranslation('products')

    const data = useStaticQuery(
        graphql`
            query {
                sample: file(relativePath: { eq: "assets/images/products/sample.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                protein: file(relativePath: { eq: "assets/images/products/protein.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                preWorkout: file(relativePath: { eq: "assets/images/products/pre-workout.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                massGain: file(relativePath: { eq: "assets/images/products/gainers.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                wLoss: file(relativePath: { eq: "assets/images/products/w-loss.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                creatine: file(relativePath: { eq: "assets/images/products/creatine.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                aminos: file(relativePath: { eq: "assets/images/products/aminos.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                vits: file(relativePath: { eq: "assets/images/products/vitamins.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `,
    )
    
    const sampePic = data.sample.childImageSharp.fluid.src
    const protein = data.protein.childImageSharp.fluid.src
    const preWorkout = data.preWorkout.childImageSharp.fluid.src
    const massGain = data.massGain.childImageSharp.fluid.src
    const wLoss = data.wLoss.childImageSharp.fluid.src
    const creatine = data.creatine.childImageSharp.fluid.src
    const aminos = data.aminos.childImageSharp.fluid.src
    const vits = data.vits.childImageSharp.fluid.src

    let id = 0
    function createData(product, description, image) {
        id += 1
        return {id, product, description, image}
    }

    const rows = [
        createData(t('protein'), t('protein_dsc'), protein),
        createData(t('pre_workout'), t('pre_workout_dsc'), preWorkout),
        createData(t('mass_gainer'), t('mass_gainer_dsc'), massGain),
        createData(t('weight_loss'), t('weight_loss_dsc'), wLoss),
        createData(t('creatine'), t('creatine_dsc'), creatine),
        createData(t('aminos'), t('aminos_dsc'), aminos),
        createData(t('vitamins'), t('vitamins_dsc'), vits),
    ]

    const [expanded, setExpanded] = React.useState(false)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <div id="products-section">
                <div className="container-full-width">
                    <div className="container-inner-md pb-3">
                        <h2 className="reset-margin center-font highlight pv-3">{t('heading')}</h2>

                        <div className="grid-container grid-product">
                            {rows.map(row => (
                                <Card key={row.id}>
                                    <CardActionArea>
                                        <CardMedia
                                            className={classes.media}
                                            image={row.image}
                                            title={row.product}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {row.product}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                            >
                                                {row.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    {/*                                     <CardActions disableSpacing>
                                        <IconButton
                                            className={clsx({[classes.expandOpen]: expanded,})}
                                            onClick={handleExpandClick}
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </CardActions>
                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            <Typography paragraph>
                                                Heat 1/2 cup of the broth in a pot until simmering,
                                                add saffron and set aside for 10 minutes.
                                            </Typography>
                                        </CardContent>
                                    </Collapse> */}
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductSection
