import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay'

const AutoplaySlider = withAutoplay(AwesomeSlider)


const HeaderCarousel = () => {

    const dataSet = useStaticQuery(graphql`
        query {
            slideSet: allFile(filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "assets/images/head-carousel"}}) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `)

/* {dataSet.slideSet.edges.node} */

    return (
        <div className="container-inner-lg">
            <AutoplaySlider
                name="header-banner"
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={5000}
            >
                {dataSet.slideSet.edges.map(item => (
                    <div
                    data-src={item.node.childImageSharp.fluid.src}
                    key={item.node.id}
                    ></div>
                ))}
            </AutoplaySlider>
        </div>
    )

}

export default HeaderCarousel
