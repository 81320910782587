import React from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Img from 'gatsby-image'

function Szymon() {

    const data = useStaticQuery(
        graphql`
            query {
                staffBg: file(relativePath: { eq: "assets/images/staff/szymon-smoke.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                staffProfile: file(relativePath: { eq: "assets/images/staff/szymon-profile-square.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `,
    )

    const profilePic = data.staffProfile.childImageSharp.fluid
    const backgroundFluidImageStack = [
        data.staffBg.childImageSharp.fluid,
        `linear-gradient(rgba(220, 15, 15, 0.73), rgba(29, 26, 5, 0.6))`
    ].reverse()
    const { t } = useTranslation('homepage')

    return (
        <div id="staff-section" className="bg">
            <div className="container-full-width">
                <div className="container-inner-md pb-3 pt-1">
                    <h2 className="center-font highlight pv-1">{t('meet_team')}</h2>
                    <Box mb={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <div className="center mx-1 px-1">
                                    <Img
                                        fluid={profilePic}
                                        style={{
                                            borderRadius: '50%',
                                            maxWidth: '200px',
                                            margin: '0 auto',
                                            filter: 'drop-shadow(0 0 0.85rem rgba(0, 0, 0, 0.87))',
                                        }}
                                    />

                                    <h3>Szymon Nowicki</h3>
                                    <p className="position">{t('store_manager')}</p>
                                    <p className="credentials">
                                        {t('personal_trainer')} <span className="dot">•</span>{' '}
                                        {t('qualified_dietician')} <span className="dot">•</span>{' '}
                                        {t('bodybuilder')} <span className="dot">•</span>{' '}
                                        {t('nabba_winner')}
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <div className="outer-box">
                                    <blockquote>
                                        <p>{t('answer_questions')}</p>
                                        <p>{t('drop_in')}</p>
                                    </blockquote>
                                    <BackgroundImage
                                        Tag="div"
                                        className="inner-box"
                                        fluid={backgroundFluidImageStack}
                                        critical={true}
                                        style={{
                                            backgroundPosition: 'top',
                                        }}
                                    ></BackgroundImage>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Szymon